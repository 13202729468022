import { FooterSVG, Leaf, NewsLetterHeader } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  CtaMask: Leaf,
  NewsletterHeader: {
    SVG: NewsLetterHeader.AveveAgrarisch,
    className: 'newsletter-svg',
  },
  breadcrumb: {
    display: true,
  },
  button: {
    ctaBanner: {
      image: 'primary-with-background',
      text: 'primary',
    },
    dealerOverview: {
      details: 'primary',
      navigation: 'secondary',
    },
    infoSection: 'tertiary',
    shortBanner: 'primary',
  },
  footer: {
    svg: {
      Desktop: FooterSVG.Desktop,
      Mobile: FooterSVG.Mobile,
      Tablet: FooterSVG.Tablet,
    },
    version: 'V2',
  },
  heroBanner: {
    mask: true,
  },
  mapSearchRadius: 40000, // in meters!!
});

export default config;
